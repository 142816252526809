<template>
    <docs-group
        title="Суд второй инстанции (апелляция)"
        deadline="
            в течение 10 дней с момента получения на руки решения суда первой инстанции.
        "
        dino-message="У тебя точно все получится!"
    >
        <template #description>
            Заполнив анкету ниже, вы получите на почту автоматически сгенерированную
            апелляционную жалобу. Вместе с жалобой в суде второй инстанции нужно подавать
            ходатайства (только те, которые вы не заявляли ранее).
            Помните, что апелляция&nbsp;— последняя возможность приобщить доказательства.
            Если, на ваш взгляд, документ требует правок, то вы можете внести их самостоятельно
            или написать нашим юристам на&nbsp;<email-us />
        </template>

        <p class="docs__steps-intro">
            Для апелляционного суда потребуется такой же набор ходатайств, что и для первого,
            а также апелляционная жалоба. Эти документы можно сформировать прямо здесь,
            в Генерапторе.
        </p>
        <ol class="docs__steps">
            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Заполните ходатайства
                </p>
                <base-button
                    view="secondary"
                    type="route"
                    to="/filing/court-motions"
                >
                    <template #icon>
                        <base-icon><icon-edit /></base-icon>
                    </template>
                    Сгенерировать ходатайства
                </base-button>
            </li>
            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Заполните апелляционную жалобу
                </p>
                <base-button
                    view="secondary"
                    type="route"
                    to="/filing/appeal-court"
                >
                    <template #icon>
                        <base-icon><icon-edit /></base-icon>
                    </template>
                    Сгенерировать жалобу
                </base-button>
            </li>
            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Подайте документы
                </p>
                <p class="docs__steps-step-paragraph">
                    Заполненные документы надо распечатать и подать в суд первой инстанции
                    <strong>(тот, который вынес обжалуемое вами решение)</strong>.
                    Как это сделать?
                </p>
                <ul
                    class="
                        docs__steps-step-bullets
                        docs__steps-step-bullets--margin-small
                    "
                >
                    <li class="docs__steps-step-bullets-item">
                        Лично&nbsp;— прийти в канцелярию суда и подать документы,
                        при этом возьмите второй экземпляр&nbsp;— на нем поставят
                        отметку о принятии;
                    </li>
                    <li class="docs__steps-step-bullets-item">
                        по почте&nbsp;— можно также отправить жалобу
                        с ходатайствами по почте России.
                    </li>
                </ul>
                <link-button
                    type="link"
                    arrow
                    :to="`${oiLegalBaseURL}/instruction/help-yourself#11`"
                >
                    Подробнее про апелляцию
                </link-button>
            </li>
        </ol>
    </docs-group>
</template>

<script>
import config from '@/config';
import LinkButton from '@/components/button/LinkButton.vue';
import BaseButton from '@/components/button/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import DocsGroup from '@/components/page-home/docs-group/DocsGroup.vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';
import IconEdit from '@/icons/IconEdit.vue';

export default {
    components: {
        LinkButton,
        BaseButton,
        BaseIcon,
        IconEdit,
        DocsGroup,
        EmailUs,
    },

    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
        };
    },
};
</script>
