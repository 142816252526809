<template>
    <div>
        <!-- Hero -->
        <section class="section section--padding-bottom-3">
            <hero-header @ctaclick="onHeroCtaClick" />
        </section>

        <section>
            <div class="container">
                <h2 class="h1 explainer__headline">
                    Куда можно обратиться за&nbsp;защитой своих прав
                    на&nbsp;свободу слова или свободу мирных собраний?
                </h2>
                <p class="explainer__description">
                    Защищать свои права вы можете в судах Российской Федерации и
                    в международных инстанциях, таких как Европейский суд по правам человека (ЕСПЧ)
                    и Комитет по правам человека в ООН (КПЧ).<br />
                    <strong>Примечание</strong>: В данное время в ЕСПЧ смогут обратиться только те,
                    кого задержали или на кого составили протокол
                    <strong>до 16 сентября 2022 г.</strong>
                </p>
            </div>
        </section>

        <section>
            <div class="container">
                <base-tabs>
                    <base-tabs-item
                        v-for="tab in courtTabs"
                        :key="tab.name"
                        :is-active="isActiveTab(tab.name)"
                        :title="tab.title"
                        :icon="tab.icon"
                        @selector-click="toggleTab(tab.name)"
                    />
                </base-tabs>
            </div>
        </section>

        <!-- How it works? -->
        <section class="section section--padding-bottom-3 section--slider">
            <div class="container">
                <keep-alive>
                    <component :is="stepsComponent" />
                </keep-alive>
            </div>
        </section>

        <!-- Docs -->
        <keep-alive>
            <component :is="docsComponent" />
        </keep-alive>

        <!-- FAQ -->
        <section class="section section--padding-bottom-2">
            <div class="container">
                <court-faq :questions="questions" />
            </div>
        </section>
        <!-- More questions? -->
        <section
            class="section section--padding-top-1 section--padding-bottom-2 section--colored"
        >
            <div class="container">
                <post-faq />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import BaseTabs from '@/components/tabs/BaseTabs.vue';
import BaseTabsItem from '@/components/tabs/BaseTabsItem.vue';
import ExplainerHRC from '@/components/page-home/explainer/ExplainerHRC.vue';
import ExplainerECHR from '@/components/page-home/explainer/ExplainerECHR.vue';
import HeroHeader from '@/components/page-home/HeroHeader.vue';
import DocsECHR from '@/components/page-home/docs/HomeDocsECHR.vue';
import DocsHRC from '@/components/page-home/docs/HomeDocsHRC.vue';
import CourtFaq from '@/components/page-home/faq/CourtFaq.vue';
import PostFaq from '@/components/page-home/PostFaq.vue';
import echrLogo from '@/assets/echr-logo.png';
import hrcLogo from '@/assets/hrc-logo.png';

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',

    components: {
        ExplainerECHR,
        ExplainerHRC,
        CourtFaq,
        HeroHeader,
        PostFaq,
        DocsECHR,
        DocsHRC,
        BaseTabs,
        BaseTabsItem,
    },

    data() {
        return {
            activeTab: 'HRC', // 'ECHR' 'HRC'
            courtTabs: [
                {
                    title: 'КПЧ',
                    name: 'HRC',
                    icon: hrcLogo,
                },
                {
                    title: 'ЕСПЧ',
                    name: 'ECHR',
                    icon: echrLogo,
                },
            ],
            questions: [
                /* {
                   title: `
                    Я слышал, что решения КПЧ не являются обязательными к исполнению
                    для государства. Так ли это?
                    `,
                    text: `
                    <p>
                        Действительно, с одной стороны, Комитет по правам человека не является
                        судом, как, например, Европейский суд по правам человека. Кроме того,
                        в структуре Комитета нет органа, обеспечивающего исполнение решений. Однако
                        обязательный характер решений Комитета («соображений») вытекает из самого
                        существа международного договора. Статья 2 Международного Пакта
                        о гражданских и политических правах, пункт 3 (а) гласит:
                    </p>
                    <p><blockquote>
                        «Каждое участвующее в настоящем Пакте Государство обязуется обеспечить
                        любому лицу, права и свободы которого, признаваемые Пактом, нарушены,
                        эффективное средство правовой защиты…»
                    </blockquote></p>`,
                },
                {
                    title: `
                    Я уехал за границу. Как мне ознакомиться с делом и подать кассационную жалобу?
                    `,
                    text: `
                    <p>
                        В исключительных случаях (как, например, нахождение за границей)
                        для ознакомления с материалами дела, получения заверенных копий
                        судебных актов и для отправки кассационной жалобы мы можем предоставить
                        адвоката. Если дело рассматривалось в городе, где нет сотрудничающего
                        с ОВД-Инфо адвоката, то возможны два варианта:
                    </p>
                    <ol>
                        <li>
                            Найти адвоката самостоятельно и оплатить его услуги через платформу
                            <a href="http://zaodno.org">“Заодно”</a>.
                        </li>
                        <li>
                            Оформить судебную доверенность на своего знакомого в России.
                            О том, как именно получить копии и отправить жалобу, юристы ОВД-Инфо
                            подробно проинструктируют. Важно, чтобы в доверенности были прописаны
                            эти полномочия.
                        </li>
                    </ol>`,
                }, */
                {
                    title: `
                    Я слышал, что решения ЕСПЧ больше не исполняются Россией
                    и подавать туда жалобу нет смысла. Это так?
                    `,
                    text: `
                    <p>
                        Исполнение постановлений ЕСПЧ является обязательным для России
                        в силу действия самой Европейской Конвенции. Ее юрисдикция распространяется
                        на нарушения, произошедшие до 16 сентября 2022&nbsp;года. А также
                        в силу Венской конвенции о праве международных договоров
                        1969&nbsp;г.&nbsp;— согласно ст.&nbsp;27, <blockquote>«Участник [договора]
                        не может ссылаться на положения своего внутреннего права в качестве
                        оправдания для невыполнения им договора».</blockquote> Закон о неисполнении
                        постановлений ЕСПЧ не соответствует нормам международного права.
                        Тем не менее, этот закон значит, что Россия, скорее всего, действительно
                        не будет исполнять свои обязательства, в том числе по выплате компенсаций.
                        Однако это не значит, что смысла подавать жалобу в ЕСПЧ нет. Постановления
                        Европейского суда бессрочны, и добиться их исполнения можно будет
                        после отмены закона о неисполнении.
                    </p>
                    `,
                },
                {
                    title: `
                    Что, если в моем случае есть важные нюансы,
                    которые не учитываются в ваших шаблонах?
                    `,
                    text: `
                    <p>
                        В наших шаблонах мы постарались учесть все, что важно для последующего
                        обжалования в Европейском суде, а также все, что на практике срабатывало
                        в российских судах. Однако ситуации бывают разные, и мы готовы помочь
                        с корректированием жалобы под ваш случай, если посчитаем это необходимым.
                    </p>
                    <p>
                        Если вы делаете апелляционную жалобу через Генераптор, укажите
                        все эти нюансы в поле «Свободный комментарий» в анкете. Если вы работаете
                        с каким-то другим шаблоном и вам нужна помощь в его корректировании,
                        напишите нам на <a href="mailto:legal@ovdinfo.org">legal@ovdinfo.org</a>.
                    </p>`,
                },
                {
                    title: 'Как подготовиться к суду?',
                    text: `
                    Документы, описанные выше,&nbsp;— это «программа минимум» для подготовки.
                    Помимо этого, вы можете проверить, не истекли ли сроки давности по вашему делу,
                    есть ли процессуальные несостыковки в вашем деле. Вы также можете собрать
                    доказательства, смягчающие ответственность.
                    <a
                        href="${config.oiLegalBaseURL}/instruction/help-yourself#7-2"
                        target="_blank"
                    >Подробнее об этом читайте тут</a>.
                    `,
                },
                {
                    title: 'Стоит ли идти в суд? Есть ли в этом смысл?',
                    text: `
                    Если вам вменяют статью, которая не предполагает арест в качестве наказания,
                    мы рекомендуем сходить в суд. Это важно для последующего обжалования
                    в Европейском суде. Если же у вас статья, которая предполагает арест,
                    то учтите: суды не могут назначить административный арест в отсутствие
                    привлекаемого лица&nbsp;— то есть вас. Соответственно, если на заседании
                    не появляться, арест вынести не смогут.
                    `,
                },
                {
                    title:
                        'Что, если я пропустил срок подачи апелляционной жалобы?',
                    text: `
                    Можно попытаться восстановить срок на подачу апелляционной жалобы.
                    Для восстановления сроков должна быть уважительная причина, но точного списка
                    таких причин в законах нет. Нередко суды восстанавливают срок и без особо
                    уважительных причин&nbsp;— поэтому попытаться стоит в любом случае.
                    <a
                        href="${config.oiLegalBaseURL}/instruction/help-yourself#10-3"
                        target="_blank"
                    >Инструкция и шаблон тут</a>.
                    `,
                },
                {
                    title:
                        'Меня задержали не на акции, а до/после нее — подойдёт ли ваш шаблон?',
                    text: `
                    Шаблон подойдет для тех, кого задержали на акции или после нее в результате
                    распознавания по камерам. Если вас задержали до акции, напишите об этом
                    в поле «Свободный комментарий» в анкете (если готовите апелляционную жалобу)
                    или напишите нам почту <a href="mailto:legal@ovdinfo.org">legal@ovdinfo.org</a>
                    (если вы работаете с каким-то другим шаблоном и вам нужна помощь
                    в его корректировании).
                    `,
                },
                {
                    title: 'Мне также нужны другие ходатайства, где их взять?',
                    text: `
                    Полный набор всех имеющихся у нас шаблонов
                    <a
                        target="_blank"
                        href="https://docs.google.com/document/d/1IGRAsZKzpyJ3RX6lWwCnfmqDFyyLAE-E0vO1UGt8e34/edit"
                    >тут</a>.
                    `,
                },
                {
                    title: 'Какую позицию занять: участвовал в акции или нет?',
                    text: `
                    Мы рекомендуем говорить правду. На практике разницы в ответственности
                    для участников/неучастников нет (исключение&nbsp;— если вы несовершеннолетний).
                    Ваша позиция может повлиять на шансы в Европейском суде&nbsp;— если вы
                    не признаете участие, ЕСПЧ с меньшей вероятностью признает нарушение 11 статьи
                    Конвенции по правам человека. Поэтому важно: если вы заявляете в российском
                    суде, что не участвовали в акции, важно прописать в процессуальных документах
                    «альтернативные» аргументы («даже если суд посчитает меня участником,
                    меня нельзя привлекать к ответственности, так как у меня есть право
                    на свободу собраний»). Такие аргументы уже есть в наших шаблонах.
                    `,
                },
                {
                    title: 'Когда и как заявить ходатайства?',
                    text: `<p>Есть два варианта: </p>
                    <ol>
                        <li>
                            Подать прямо в заседании: в начале заседания судья спросит,
                            есть ли у вас ходатайства,&nbsp;— говорите, что есть,
                            и передаете бумаги судье.
                        </li>
                        <li>
                            Прийти в суд заранее и подать документы в канцелярию.
                            Возьмите с собой копию каждого документа&nbsp;— попросите поставить
                            на ней отметку о принятии. Заодно можно ознакомится с делом.
                    </li>
                    </ol>
                    <p>Мы обычно выбираем первый вариант.</p>
                    `,
                },
                {
                    title: 'Что взять с собой в суд?',
                    text: `
                    <p>
                        В суд лучше прибыть за 30 минут до слушаний.
                        При походе в суд берите с собой:
                    </p>
                    <ul>
                        <li>все подготовленные и подписанные документы,</li>
                        <li>паспорт,</li>
                        <li>маски и перчатки,</li>
                        <li>извещение (повестку), чтобы при случае показать ее на входе,</li>
                        <li>
                            бумагу и ручку (на случай если надо будет подать дополнительные
                            ходатайства&nbsp;/ жалобы),
                        </li>
                        <li>
                            телефон с высоким зарядом батареи
                            (в том числе для ведения аудиозаписи).
                        </li>
                    </ul>
                    <p>
                        Не берите ничего колющего/режущего, баллончики, штопор&nbsp;—
                        отберут на входе. Проходите к кабинету судьи, ожидаете вызова в коридоре.
                        Рекомендуем попробовать ознакомиться с делом до начала заседания,
                        чтобы не затягивать само рассмотрение.
                    </p>
                    `,
                },
                {
                    title: 'Как ознакомиться с делом? ',
                    text: `
                    <p>Ознакомиться можно тремя способами:</p>
                    <ul>
                        <li>
                            Заранее до дня слушаний специально приехать в суд для ознакомления.
                            Это позволит наиболее вдумчиво и спокойно разработать стратегию защиты.
                            Для этого надо заблаговременно позвонить в канцелярию суда
                            по телефонам, указанным на сайте суда, и согласовать время посещения.
                            Без такого согласования могут не пустить.
                        </li>
                        <li>
                            В день слушаний прийти пораньше, ознакомиться с делом до начала
                            заседания. Подойдите к секретарю или помощнику&nbsp;— они часто сидят
                            в кабинете, смежном с залом, где будет слушаться ваше дело. Попросите
                            ознакомиться с материалами, по требованию передайте
                            ходатайство об ознакомлении.
                        </li>
                        <li>
                            Заявить ходатайство в самом заседании. Судья объявит перерыв.
                            Вам дадут дело прямо в руки или скажут идти в канцелярию&nbsp;—
                            идите туда и ждите, пока вынесут дело. После ознакомления
                            возвращайтесь к залу заседания.
                        </li>
                    </ul>
                    `,
                },
                {
                    title: 'Что делать при ознакомлении с материалами дела?',
                    text: `
                    <ul>
                        <li>Сфотографировать все страницы дела;</li>
                        <li>
                            обращать внимание на формальные противоречия:
                            в протоколе нет вашей подписи, не ваша подпись, неверная дата
                            составления протокола или дата задержания, протокол в материалах дела
                            в чем-то расходится с копией протокола, которую вам выдали в полиции,
                            в рапортах указана не ваша фамилия.
                        </li>
                    </ul>
                    `,
                },
                {
                    title: 'Вы предоставите защитника?',
                    text: `
                    <a href="https://ovdinfo.org/help">
                        Заполните, пожалуйста, заявку на помощь в суде
                    </a>.
                    Дальнейший статус уточняйте по нашей горячей линии
                    (8&nbsp;800&nbsp;707&nbsp;0528) или через
                    <a href="https://t.me/ovdinfobot">Телеграм-бот</a>.
                    `,
                },
                {
                    title: 'Что вообще делать в суде, как себя вести?',
                    text: `
                    О том, что происходит в заседании и как себя вести, лучше всего
                    <a href="${config.oiLegalBaseURL}/instruction/help-yourself#8">
                        почитать в нашей инструкции
                    </a>.
                    `,
                },
                {
                    title: `
                        Если мое дело прекратили, смогу ли я пожаловаться
                        в Европейский суд по правам человека?
                    `,
                    text: `
                    <p>
                        Даже если вас не осудили за участие в акции,
                        все равно государство нарушило ваши права, если:
                    </p>
                    <ul>
                        <li>
                            вас задержали на акции или до/после нее. Само задержание
                            без оснований&nbsp;— нарушение 5 статьи Европейской Конвенции;
                        </li>
                        <li>
                            если вас доставляли в плохих условиях (долго и тесно&nbsp;/
                            жарко&nbsp;/ холодно&nbsp;/ без сидячих мест&hellip;) и/или
                            держали в отделе 48 часов в плохих условиях (не дали
                            спальное место&nbsp;/ постельные принадлежности&nbsp;/
                            не выводили в туалет по требованию&nbsp;/ камера была менее
                            4&nbsp;кв.&nbsp;м. на человека&nbsp;/ не кормили&nbsp;/
                            не поили&hellip;), можно пожаловаться по 3 статье Конвенции.
                        </li>
                    </ul>
                    <p>
                        Однако для жалобы в ЕСПЧ необходимо сначала подать иск против РФ
                        в российских судах в порядке административного судопроизводства.
                        Надо пройти 4 инстанции (1, апелляция, кассация, ВС РФ). Подать иск надо
                        в течение 3-х месяцев с момента задержания. Однако даже если вы пропустили
                        этот срок, можно попытаться его восстановить,
                        подав соответствующее ходатайство.
                    </p>
                    `,
                },
                {
                    title: 'Как подать апелляционную жалобу?',
                    text: `
                    <p>Апелляционную жалобу можно подать двумя способами:</p>
                    <ol>
                        <li>
                            <strong>Лично в суд</strong>, где рассматривалось ваше дело
                            (адресуете жалобу в Мосгорсуд, например, а физически отвозите
                            в суд первой инстанции&nbsp;— это странно, но это так). Для этого
                            распечатайте жалобу в двух экземплярах, подпишите каждый экземпляр
                            и поставьте дату. Один экземпляр жалобы сдаете в приемную суда,
                            на втором просите поставить штамп с датой принятия. Второй экземпляр
                            бережно храните, фотографию страницы со штампом присылаете нам
                            для того, чтобы и мы его хранили. Суды обычно работают
                            до 6 часов вечера в будни, у них сокращённый день в пятницу,
                            с часу до двух&nbsp;— перерыв.
                            «Приемная» в Москве может называться «Отдел обеспечения
                            судопроизводства по делам об административных правонарушениях», либо
                            в общем случае «Канцелярия по делам об административных
                            правонарушениях». Перед походом в суд позвоните в канцелярию,
                            согласуйте визит (это надо делать в период пандемии).
                        </li>
                        <li>
                            <strong>По почте заказным письмом с уведомлением</strong>
                            (лучше с описью) направляете в суд первой инстанции
                            (адресуете в Мосгорсуд, но высылаете в адрес суда первой инстанции).
                            Жалоба считается поданной в срок, если отправлена по почте до 24 часов
                            последнего дня. Фотографируете квитанцию об отправке и присылаете нам,
                            чтобы и вы, и мы ее хранили.
                        </li>
                    </ol>
                    <p>
                        Как бы вы ни подали жалобу, не забудьте уведомить нас, как только
                        вам сообщат дату суда, чтобы мы нашли защитника или проконсультировали вас.
                    </p>
                    `,
                },
                {
                    title: 'Какой срок подачи апелляционной жалобы?',
                    text: `
                    10 календарных дней с того момента, когда вы получили
                    полный текст решения (либо в заседании, либо по почте).
                    `,
                },
            ],
        };
    },

    computed: {
        docsComponent() {
            return this.activeTab === 'HRC' ? 'DocsHRC' : 'DocsECHR';
        },
        stepsComponent() {
            return this.activeTab === 'HRC' ? 'ExplainerHRC' : 'ExplainerECHR';
        },
    },

    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
        isActiveTab(tab) {
            return tab === this.activeTab;
        },
        onHeroCtaClick() {
            const docsSection = document.getElementById('docs');
            const HEADER_HEIGHT = 64;

            if (!docsSection) {
                return;
            }
            window.scrollTo({
                top: docsSection.offsetTop - HEADER_HEIGHT,
                behavior: 'smooth',
            });
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.explainer__description {
    margin-bottom: 32px;
}

// Docs

.docs__teaser {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.5;
    color: colors.$secondary-grey;
}

.docs__link {
    margin-bottom: 32px;
}

.docs__steps-intro {
    margin-bottom: 32px;
}

.docs__steps {
    list-style: revert;
    padding-left: 18px;
}

.docs__steps-step {
    position: relative;
    padding-bottom: 24px;

    &:last-child {
        padding-bottom: 0;
    }
}

.docs__steps-step:not(:last-child) {
    margin-bottom: 32px;

    &::after {
        content: "";
        position: absolute;
        left: -18px;
        bottom: 0;
        width: calc(100% + 18px);
        height: 1px;
        background-color: colors.$secondary-grey-light;
    }
}

.docs__steps-step-headline,
.docs__steps-step::marker {
    font-size: 18px;
    line-height: 1.45;
    font-weight: 700;
}

.docs__steps-step-headline {
    margin-bottom: 4px;
}

.docs__steps-step-bullets {
    list-style: disc;
    padding-left: 16px;
    margin-bottom: 24px;

    &--margin-small {
        margin-bottom: 16px;
    }
}

.docs__steps-step-bullets-item {
    margin-bottom: 4px;
}

.docs__steps-step-paragraph {
    margin-bottom: 4px;
}

.docs__tip {
    margin-top: 40px;
    padding: 16px;
    background-color: colors.$generaptor-brand-bg;
    border-radius: 8px;
    overflow: hidden;
}

.docs__tip-headline {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: colors.$generaptor-brand-dark;
}

.docs__tip-headline-icon {
    display: flex;
    margin-right: 8px;
}

.docs__tip-text {
    padding-left: 32px;
    max-width: 700px;
    font-size: 14px;
    line-height: 1.3;
    color: colors.$grey;
}
</style>
