var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.link)?_c('button',_vm._g({staticClass:"button-icon",class:{
        'button-icon--underline': _vm.underline,
        'button-icon--accent-color': _vm.accentColor,
    },attrs:{"type":"button","disabled":_vm.disabled}},_vm.$listeners),[_c('span',{staticClass:"button-icon__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('span',[_vm._t("default")],2)]):_c('a',{staticClass:"button-icon",class:{
        'button-icon--disabled': _vm.disabled,
        'button-icon--underline': _vm.underline,
        'button-icon--accent-color': _vm.accentColor,
    },attrs:{"href":_vm.to}},[_c('span',{staticClass:"button-icon__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('span',[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }