<template>
    <docs-group
        title="Суд первой инстанции"
        deadline=""
        dino-message="Теперь ты точно готов к бою!"
    >
        <template #description>
            Объяснение и набор ходатайств для подготовки к первому суду.
        </template>

        <p class="docs__steps-intro">
            Для подготовки к суду первой инстанции нужно заполнить объяснение по статье обвинения
            (это нужно, чтобы суд видел не только позицию полицейских)
            и минимальный набор ходатайств. Заполнив анкету, вы получите сгенерированные документы
            на почту. Документы нужно будет проверить, распечатать и взять с собой в суд.
        </p>
        <ol class="docs__steps">
            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Заполните объяснение
                </p>
                <ul class="docs__steps-step-bullets">
                    <li class="docs__steps-step-bullets-item">
                        Скачайте шаблон документа, выбрав статью, по которой вас обвиняют.
                    </li>
                    <li class="docs__steps-step-bullets-item">
                        Внесите свои правки в выделенных цветом местах.
                    </li>
                </ul>
                <document-selector
                    :articles="explanationByArticle"
                    button-text="Скачать объяснение"
                />
            </li>

            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Заполните ходатайства
                </p>
                <p class="docs__steps-step-paragraph">
                    Это можно сделать прямо в Генерапторе.
                </p>
                <base-button
                    view="secondary"
                    type="route"
                    to="/filing/court-motions"
                >
                    <template #icon>
                        <base-icon><icon-edit /></base-icon>
                    </template>
                    Сгенерировать ходатайства
                </base-button>
            </li>
            <li class="docs__steps-step">
                <p class="docs__steps-step-headline">
                    Подготовьтесь к суду
                </p>
                <ul
                    class="
                        docs__steps-step-bullets
                        docs__steps-step-bullets--margin-small
                    "
                >
                    <li class="docs__steps-step-bullets-item">
                        Распечатайте заполненные документы в двух экземплярах.
                    </li>
                    <li class="docs__steps-step-bullets-item">
                        Принесите документы в суд, чтобы заявить в заседании.
                    </li>
                </ul>
                <link-button
                    type="link"
                    arrow
                    :to="`${oiLegalBaseURL}/instruction/help-yourself#8`"
                >
                    Подробнее о том, как вести себя в суде
                </link-button>
            </li>
        </ol>
        <div class="docs__tip">
            <p class="docs__tip-headline">
                <span class="docs__tip-headline-icon">
                    <base-icon>
                        <icon-bulb></icon-bulb>
                    </base-icon>
                </span>
                <span>Кстати&hellip;</span>
            </p>
            <p class="docs__tip-text">
                Ходатайства подаются не только для того, чтобы их приняли, но и для того,
                чтобы их отклонили,&nbsp;— это будет серьезным аргументом на этапах
                обжалования действий и решения суда.
            </p>
        </div>
    </docs-group>
</template>

<script>
import config from '@/config';
import DocsGroup from '@/components/page-home/docs-group/DocsGroup.vue';
import LinkButton from '@/components/button/LinkButton.vue';
import BaseButton from '@/components/button/BaseButton.vue';
import DocumentSelector from '@/components/page-home/document-selector/DocumentSelector.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconBulb from '@/icons/IconBulb.vue';
import IconEdit from '@/icons/IconEdit.vue';

import {
    explanationByArticle,
} from '@/components/page-home/document-selector/explanationByArticle';

export default {
    components: {
        DocsGroup,
        LinkButton,
        BaseButton,
        DocumentSelector,
        BaseIcon,
        IconBulb,
        IconEdit,
    },
    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
            explanationByArticle,
        };
    },
};
</script>
