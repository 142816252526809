<template>
    <ul class="validation-errors">
        <li
            v-for="error in errors"
            :key="error"
            class="validation-errors__error"
        >
            <div class="validation-errors__icon">
                <base-icon>
                    <icon-attention />
                </base-icon>
            </div>
            <span>Некорректный ввод (поле "{{ error }}")</span>
        </li>
    </ul>
</template>

<script lang="ts">
import Vue from 'vue';
import IconAttention from '@/icons/IconAttention.vue';
import BaseIcon from './BaseIcon.vue';

export default Vue.extend({
    name: 'ErrorBlock',
    components: { IconAttention, BaseIcon },
    props: {
        errors: {
            type: Array,
            default: () => [],
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.validation-errors {
    font-size: 16px;
    font-weight: 700;
    color: colors.$danger;
    margin-bottom: 16px;
}

.validation-errors__error {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.validation-errors__icon {
    margin-right: 8px;
}
</style>
