<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__inner">
                <a :href="oiLegalBaseURL">
                    <img
                        class="footer__logo"
                        src="@/images/oi-logo.svg#logo-white"
                        alt="Логотип ОВД-Инфо"
                        width="214"
                        height="41"
                    />
                </a>
                <div class="footer__tiles">
                    <div class="footer__tile">
                        <tile-button
                            link
                            to="tel:+78007070528"
                            dark
                        >
                            <template #icon>
                                <base-icon><icon-phone /></base-icon>
                            </template>
                            8 800 707–05–28
                        </tile-button>
                    </div>
                    <div class="footer__tile">
                        <tile-button
                            link
                            to="tel:+74953746675"
                            dark
                        >
                            <template #icon>
                                <base-icon><icon-phone /></base-icon>
                            </template>
                            8 495 374–66–75
                        </tile-button>
                    </div>
                    <div class="footer__tile">
                        <tile-button
                            link
                            to="mailto:legal@ovdinfo.org"
                            dark
                        >
                            <template #icon>
                                <base-icon><icon-mail /></base-icon>
                            </template>
                            legal@ovdinfo.org
                        </tile-button>
                    </div>
                </div>
            </div>
            <small class="footer__ebala">
                29&nbsp;сентября 2021&nbsp;года Минюст включил ОВД-Инфо
                в&nbsp;«реестр незарегистрированных общественных объединений,
                выполняющих функции иностранного агента». Вы&nbsp;можете
                <a
                    href="https://www.change.org/p/государственная-дума-мы-требуем-отмены-законов-об-иноагентах"
                    target="_blank"
                >помочь</a>
                нам убрать с&nbsp;сайта эту маркировку
            </small>
        </div>
    </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import IconPhone from '@/icons/IconPhone.vue';
import IconMail from '@/icons/IconMail.vue';
import TileButton from '@/components/button/TileButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default Vue.extend({
    components: {
        TileButton,
        BaseIcon,
        IconPhone,
        IconMail,
    },

    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
        };
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.footer {
    background-color: colors.$dark;
    padding-top: 60px;
    padding-bottom: 52px;
    color: colors.$white;

    @include breakpoint(sm) {
        padding-top: 48px;
    }
}

.footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(sm) {
        display: block;
    }
}

.footer__logo {
    display: flex;

    @include breakpoint(sm) {
        margin-bottom: 24px;
    }
}

.footer__tiles {
    display: flex;

    @include breakpoint(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}

.footer__tile {
    &:not(:last-child) {
        margin-right: 16px;

        @include breakpoint(sm) {
            margin-right: 0;
        }
    }
}

.footer__ebala {
    display: block;
    padding-top: 24px;
    font-size: 12px;
    line-height: 1;
    color: colors.$secondary-grey-light;
}
</style>
