<template>
    <div class="petitions-list">
        <h2 class="petitions-list__headline">
            {{ headline }}
        </h2>
        <div class="petitions-list__list">
            <div
                v-for="petition in petitions"
                :key="petition.title"
                class="petitions-list__item"
            >
                <div class="petitions-list__text">
                    <p class="petitions-list__text-title">
                        {{ petition.title }}
                    </p>
                    <p class="petitions-list__text-descriptor">
                        {{ petition.descriptor }}
                    </p>
                </div>
                <div class="petitions-list__item-button-wrapper">
                    <icon-button
                        class="petitions-list__item-button"
                        accent-color
                        underline
                        link
                        :to="`https://docs.google.com/document/export?format=docx&id=${petition.docId}`"
                        target="_blank"
                    >
                        <template #icon>
                            <base-icon><icon-download /></base-icon>
                        </template>
                        скачать
                    </icon-button>

                    <base-button
                        class="petitions-list__item-button petitions-list__item-button--mobile"
                        type="link"
                        view="secondary"
                        :to="`https://docs.google.com/document/export?format=docx&id=${petition.docId}`"
                        target="_blank"
                    >
                        <template #icon>
                            <base-icon><icon-download /></base-icon>
                        </template>
                        Скачать
                    </base-button>
                </div>
            </div>
        </div>
        <div class="petitions-list__download-button-wrapper">
            <base-button
                type="link"
                target="_blank"
                :to="`https://docs.google.com/document/export?format=docx&id=1IGRAsZKzpyJ3RX6lWwCnfmqDFyyLAE-E0vO1UGt8e34`"
            >
                <template #icon>
                    <base-icon><icon-download /></base-icon>
                </template>
                Скачать все
            </base-button>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import IconButton from '@/components/button/IconButton.vue';
import BaseButton from '@/components/button/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconDownload from '@/icons/IconDownload.vue';

export default Vue.extend({
    components: {
        IconButton,
        BaseIcon,
        IconDownload,
        BaseButton,
    },
    data() {
        return {
            headline: 'Программа «Минимум»',
            petitions: [
                {
                    title: 'Ходатайство об ознакомлении с материалами дела',
                    descriptor:
                        'Чтобы просмотреть материалы дела и найти новые аргументы в свою защиту',
                    docId: '1PXafkkVX9Erfk3NYzfg0-oIxgawYWvz40wuRdtHXJyE',
                },
                {
                    title: 'Ходатайство о допуске защитника',
                    descriptor:
                        'Если у вас будет защитник без адвокатского статуса',
                    docId: '1OCTNPY2NnDBCXTNB4YFBXL7KFDVx_lxKgEaVq-QoniQ',
                },
                {
                    title: 'Ходатайство о вызове стороны обвинения',
                    descriptor:
                        'Просьба вызвать в суд прокурора — чтобы обвинителем в процессе был он, а не судья',
                    docId: '1aC6q77UiZ80T8asgbxf6MO_X-SQdy1vnHmDgHwCLZXE',
                },
                {
                    title: 'Ходатайство о вызове на допрос полицейских',
                    descriptor:
                        'Просьба вызвать в суд для дачи свидетельских показаний сотрудников полиции, которые вас задерживали',
                    docId: '1rZZWqjUeD5vYdFZbXP6vIb5YdXPW5kYmEcUc1YWhqTw',
                },
                {
                    title: 'Ходатайство о приобщении фото/видео',
                    descriptor: 'Фотографии или видеосъемка вашего задержания',
                    docId: '1XtmUlKndYPB074aDd5eY3dRFiVt_5_NJDebZSWY2Vic',
                },
                {
                    title: 'Ходатайство о вызове свидетеля защиты',
                    descriptor:
                        'Просьба вызвать тех, кто покажет, что вы вели себя мирно и вообще никак не нарушали общественный порядок',
                    docId: '1gntYzJrY939YzF9jJ4LqsoP5nZNoQEnYQxQJW4QV48Q',
                },
            ],
        };
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.petitions-list__headline {
    color: colors.$generaptor-brand;
    margin-bottom: 24px;
    letter-spacing: -0.03em;

    @include mobile {
        font-size: 28px;
        line-height: 1.3;
        letter-spacing: -0.02em;
    }
}

.petitions-list__list {
    margin-bottom: 40px;

    @include mobile {
        margin-bottom: 58px;
    }
}

.petitions-list__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid colors.$divider;
    padding: 8px 0;

    @include mobile {
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px 0;
    }

    &:first-child {
        border-top: 1px solid colors.$divider;
    }
}

.petitions-list__text {
    position: relative;
    padding-left: 32px;

    @include mobile {
        margin-bottom: 24px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        background-image: url("@/images/icon-doc.svg");
    }
}

.petitions-list__text-title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
    color: colors.$secondary-grey;
}

.petitions-list__text-descriptor {
    font-size: 14px;
    line-height: 1.2;
    color: colors.$grey;
}

.petitions-list__item-button-wrapper {
    align-self: center;

    @include mobile {
        padding-left: 32px;
        align-self: flex-start;
    }

    @include desktop {
        margin-left: grid.$space-3;
    }
}

.petitions-list__item-button {
    @include mobile {
        display: none !important;
    }

    &--mobile {
        display: none !important;

        @include mobile {
            display: flex !important;
        }
    }
}

.modal .petitions-list__download-button-wrapper {
    @include mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        background-color: colors.$white;
        box-shadow: 0 -9px 15px rgba(178 181 190 / 29%);

        .button {
            width: 100%;
            justify-content: center;
        }
    }
}
</style>
