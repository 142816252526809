<template>
    <!-- Docs -->
    <section
        id="docs"
        class="section section--padding-bottom-3"
    >
        <div class="container">
            <div class="docs">
                <modal-overlay
                    :is-open="isModalOpen"
                    @close="() => (isModalOpen = false)"
                >
                    <petitions-list></petitions-list>
                </modal-overlay>
                <h2 class="h1">
                    Документы для суда
                </h2>
                <p class="docs__teaser">
                    К судам надо готовить документы. Ниже мы&nbsp;описываем какие,
                    как их сделать и как подать.<br />
                    Наши документы составлены специально с&nbsp;учётом перспектив в&nbsp;КПЧ.
                </p>
                <div class="docs__link">
                    <link-button
                        type="button"
                        @click="isModalOpen = true"
                    >
                        Мне нужно конкретное ходатайство
                    </link-button>
                </div>

                <first-instance-court-docs />

                <appeal-court-docs />

                <docs-group
                    title="Суд третьей инстанции (первая кассация)"
                    deadline="не установлен."
                    dino-message="У тебя точно все получится!"
                >
                    <template #description>
                        После прохождения апелляции вы можете подать жалобу в кассационный суд.
                        Для этого обязательно нужно получить в суде первой инстанции заверенные
                        копии судебных актов (постановление суда первой инстанции и решение суда
                        апелляционной инстанции) и приложить к жалобе.<br />
                        В этом шаге вы узнаете, как получить копии и собрать все необходимые
                        документы.
                    </template>

                    <p class="docs__steps-intro">
                        Для обращения в кассационный суд потребуется следующее:
                    </p>
                    <ol class="docs__steps">
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Подготовьте кассационную жалобу
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Это можно сделать прямо в Генерапторе.
                            </p>
                            <base-button
                                view="secondary"
                                type="route"
                                to="/filing/court-of-cassation"
                            >
                                <template #icon>
                                    <base-icon><icon-edit /></base-icon>
                                </template>
                                Сгенерировать жалобу
                            </base-button>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Соберите пакет документов
                            </p>
                            <p class="docs__steps-step-paragraph">
                                К кассационной жалобе нужно приложить:
                            </p>
                            <ul class="docs__steps-step-bullets">
                                <li class="docs__steps-step-bullets-item">
                                    <strong>заверенные</strong> копии всех постановлений
                                    и решений по делу об административном правонарушении.
                                    Получить их можно, обратившись в суд первой инстанции,
                                    куда материалы вашего дела вернулись после апелляции.
                                    Лучше взять сразу по две копии (итого, 4 документа)&nbsp;—
                                    для кассационного суда и для Верховного Суда.
                                    Правильно заверенной будет считаться копия,
                                    где есть гербовая печать суда, отметка “копия верна”
                                    с заверительной подписью должностного лица,
                                    отметка о вступлении в силу; копия также должна быть прошита
                                    (либо место скрепления опечатано) и пронумерована;
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    копии кассационной жалобы по количеству участников процесса.
                                    Например, если вы ходатайствовали в первой или второй
                                    инстанции о вызове прокурора и защитника по делу,
                                    вам нужно будет к вашей жалобе дополнительно приложить
                                    еще две копии жалобы.
                                    Важно: копии жалобы для свидетелей прикладывать не нужно;
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    копию доверенности на вашего представителя (если он действует
                                    по доверенности) или копия ордера адвоката&nbsp;— в случае,
                                    если жалобу подаёте не вы, а ваш адвокат.
                                </li>
                            </ul>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Отправьте жалобу
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Подайте жалобу и приложенные к ней документы ценным письмом
                                с описью вложения Почтой России напрямую в кассационный суд.
                                Адрес для корреспонденции можно найти на официальном сайте
                                кассационного суда.
                            </p>
                            <p class="docs__steps-step-paragraph">
                                В России 9 кассационных судов
                                (по количеству кассационных округов).
                                Узнать, в какой суд вам надо подать жалобу, можно, например, в
                                <a
                                    href="https://sudrf.ru/index.php?id=300&searchtype=fs"
                                    target="_blank"
                                >ГАС “Правосудие”</a>.
                                Наберите в строке “наименование судебного органа”
                                словосочетание “кассационный суд”
                                и выберете субъект, в котором рассматривалось ваше дело.
                                Система выдаст вам наименование кассационного суда, который
                                осуществляет юрисдикцию над вашим субъектом.
                                К примеру, для Ивановской области это будет
                                Второй кассационный суд,
                                а для Приморского края&nbsp;— Девятый кассационный суд.
                            </p>
                        </li>
                    </ol>
                </docs-group>

                <docs-group
                    title="Суд четвертой инстанции (вторая кассация)"
                    deadline="
                        не ограничен. Рекомендованный срок обращения в кассационный суд&nbsp;—
                        1 год после получения решения апелляционного суда.
                    "
                    dino-message="У тебя точно все получится!"
                >
                    <template #description>
                        Последней инстанцией является Верховный Суд Российской Федерации (ВС РФ).
                    </template>

                    <ol class="docs__steps">
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Подготовьте жалобу и соберите необходимые документы
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Подготовка жалобы в ВС РФ практически ничем
                                не отличается от подготовки жалобы в кассационный суд.
                                Единственное отличие&nbsp;— к жалобе в ВС РФ также
                                необходимо приложить заверенную копию постановления
                                кассационного суда.
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Это можно сделать прямо в Генерапторе.
                            </p>
                            <base-button
                                view="secondary"
                                type="route"
                                to="/filing/court-of-cassation"
                            >
                                <template #icon>
                                    <base-icon><icon-edit /></base-icon>
                                </template>
                                Сгенерировать жалобу
                            </base-button>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Отправьте жалобу
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Отправить жалобу и приложенные к ней документы следует
                                по адресу Верховного суда России:<br />
                                Российская Федерация, 121260, Москва, ул. Поварская, д. 15.
                            </p>
                        </li>
                    </ol>
                </docs-group>

                <docs-group
                    title="Комитет по правам человека в ООН (КПЧ)"
                    deadline="
                        5 лет, если вы не обращались в другие международные органы,
                        и 3 года, если обращались (например, ЕСПЧ).
                    "
                    dino-message="У тебя точно все получится!"
                >
                    <template #description>
                        Инструкция, документы и сопровождение от юристов ОВД-Инфо
                        для обжалования решения российских судов
                        в Комитете по правам человека в ООН.
                    </template>

                    <ol class="docs__steps">
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Ознакомьтесь с материалами дела в суде
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Мы подготовили для вас
                                <a
                                    href="https://oi.legal/instruction/kak-sobrat-dokumenty-dlya-espch-ili-kpch-vo-vremya-i-posle-administrativnyh-sudov"
                                    _target="blank"
                                >подробную инструкцию</a>,
                                ниже — её краткая версия:
                            </p>
                            <ul class="docs__steps-step-bullets">
                                <li class="docs__steps-step-bullets-item">
                                    Если вы уже обладаете фотографиями материалов дела
                                    (после суда второй инстанции),
                                    повторно идти в суд не нужно. Если же вы не успели
                                    это сделать&nbsp;— позвоните
                                    в канцелярию суда первой инстанции и договоритесь о встрече.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    В назначенную дату и время придите в канцелярию суда
                                    с паспортом и попросите
                                    ознакомиться с материалами вашего дела.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    Отфотографируйте каждую страницу дела в хорошем качестве.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    Также отфотографируйте кассационные жалобы,
                                    постановления кассационного суда и ВС РФ.
                                </li>
                            </ul>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Подача жалобы в КПЧ
                            </p>
                            <ul class="docs__steps-step-bullets">
                                <li class="docs__steps-step-bullets-item">
                                    Напишите нам на почту <mail-to email="un@ovdinfo.org" />,
                                    указав, что вам требуется помощь с подачей жалобы
                                    (приложите фотографии материалов дела).
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    После того, как жалоба будет готова,
                                    юристы сами отправят жалобу в КПЧ, известив вас об этом.
                                </li>
                            </ul>
                            <!-- eslint-disable max-len -->
                            <link-button
                                type="link"
                                arrow
                                :to="`${oiLegalBaseURL}/instruction/chto-teper-s-espch-i-kakie-alternativy#1`"
                            >
                                Подробнее про КПЧ
                            </link-button>
                            <!-- eslint-enable max-len -->
                        </li>
                    </ol>
                </docs-group>
            </div>
        </div>
    </section>
</template>

<script>
import config from '@/config';
import DocsGroup from '@/components/page-home/docs-group/DocsGroup.vue';
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import PetitionsList from '@/components/petitions-list/PetitionsList.vue';
import MailTo from '@/components/mail-to/MailTo.vue';
import LinkButton from '@/components/button/LinkButton.vue';
import BaseButton from '@/components/button/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconEdit from '@/icons/IconEdit.vue';
import FirstInstanceCourtDocs from './FirstInstanceCourtDocs.vue';
import AppealCourtDocs from './AppealCourtDocs.vue';

export default {
    name: 'HomeDocsHRС',
    components: {
        DocsGroup,
        ModalOverlay,
        PetitionsList,
        MailTo,
        LinkButton,
        BaseButton,
        BaseIcon,
        IconEdit,
        FirstInstanceCourtDocs,
        AppealCourtDocs,
    },
    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
            isModalOpen: false,
        };
    },
};
</script>
