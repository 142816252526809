import config from '@/config';
import explainerStep1Pic from '@/components/page-home/explainer/explainer-images/steps-illustration-1.jpg';
import explainerStep2Pic from '@/components/page-home/explainer/explainer-images/steps-illustration-2.jpg';
import explainerStep3Pic from '@/components/page-home/explainer/explainer-images/steps-illustration-3.jpg';
import explainerStep4Pic from '@/components/page-home/explainer/explainer-images/steps-illustration-4echr.jpg';
import explainerStep4HRCPic from '@/components/page-home/explainer/explainer-images/steps-illustration-4hrc.jpg';
import explainerStep5HRCPic from '@/components/page-home/explainer/explainer-images/steps-illustration-5hrc.jpg';
import explainerStep6HRCPic from '@/components/page-home/explainer/explainer-images/steps-illustration-6hrc.jpg';

const stepsCommon = [
    {
        image: explainerStep1Pic,
        imageAlt: 'Росгвардеец ведёт динозаврика в наручниках',
        title: 'На вас составлен протокол об административном правонарушении',
        descriptor:
            'Полиция задерживает вас на акции протеста или составляет протокол за высказывание.',
    },
    {
        image: explainerStep2Pic,
        imageAlt:
            'Динозаврик с документами в лапках направляется в здание с цифрой один',
        title: 'Суд первой инстанции',
        descriptor:
            'Полиция передает дело и протокол в суд первой инстанции. Суд выясняет, было ли правонарушение и какое вынести наказание.',
    },
    {
        image: explainerStep3Pic,
        imageAlt:
            'Динозаврик с документами в лапках направляется в здание с цифрой два',
        title: 'Суд второй инстанции',
        descriptor:
            'Если суд первой инстанции вынес решение против вас, его надо обжаловать в суде второй (апелляционной) инстанции.',
    },
];

export const homeContent = {
    HRC: {
        title: 'Как защитить свои права в Комитете по правам человека в ООН (КПЧ)?',
        intro: `Если Комитет признает нарушение, можно обратиться в российский суд
            и потребовать компенсацию материального вреда. Российский суд нередко
            выплачивает компенсации за незаконные задержания или аресты,
            если дело было прекращено — в некоторых случаях суд взыскивал с полицейских
            до 100 тысяч рублей за незаконные задержания или административные аресты.`,
        steps: [
            ...stepsCommon,
            {
                image: explainerStep4HRCPic,
                imageAlt:
                    'Динозаврик с документами в лапках направляется в здание Кассационного суда',
                title: 'Суд третьей инстанции',
                descriptor:
                    'После получения апелляционного решения вы можете подать жалобу в кассационный суд. Для этого обязательно нужно получить в суде первой инстанции заверенные копии судебных актов (постановление суда первой инстанции и решение суда апелляционной инстанции) и приложить к жалобе. Срок для подачи кассационной жалобы не установлен.',
            },
            {
                image: explainerStep5HRCPic,
                imageAlt:
                    'Динозаврик с документами в лапках идет долгой дорогой в здание Верховного суда',
                title: 'Суд четвертой инстанции',
                descriptor:
                    'Если и кассационный суд не услышит доводы, тогда стоит обратиться в Верховный Суд Российской Федерации. Правила обращения такие же, как и для кассационного суда.',
            },
            {
                image: explainerStep6HRCPic,
                imageAlt:
                    'Динозаврик с конвертом в лапках идет к почтовому ящику с надписью ООН',
                title: 'Комитет по правам человека (КПЧ)',
                descriptor:
                    'После прохождения всех четырех инстанций можно обратиться в Комитет по правам человека ООН (КПЧ). Комитет не присуждает компенсации, но может рекомендовать государству выплатить справедливую компенсацию без указания суммы, а также принять другие индивидуальные или общие меры. Например, рекомендовать отменить закон или изменить практику.',
            },
        ],
    },
    ECHR: {
        title: 'Как защитить свои права в Европейском суде по правам человека (ЕСПЧ)?',
        intro: `Россию исключили из Совета Европы, но всё еще можно подавать жалобы по нарушениям,
            которые произошли до 15 сентября 2022 года включительно. ЕСПЧ рассмотрит такие жалобы,
            вынесет своё решение и может присудить компенсацию в среднем около 5000 евро.
            После возвращения в Совет Европы, Россия будет обязана исполнить решение.`,
        steps: [
            ...stepsCommon,
            {
                image: explainerStep4Pic,
                imageAlt:
                    'Динозаврик мечтает о Европейском правосудии и запускает документы бумажным самолётиком',
                title: 'Европейский суд по правам человека (ЕСПЧ)',
                descriptor:
                    `Если не получилось добиться справедливости в апелляции, можно обратиться в ЕСПЧ по правонарушениям российских властей, совершенных до 15 сентября 2022 года. Если после, то нужно будет <a href="${config.oiLegalBaseURL}/instruction/chto-teper-s-espch-i-kakie-alternativy#1">продолжить защищать свои права</a> в России: в кассационном и верховном судах, а потом <a href="https://www.ohchr.org/ru/treaty-bodies/ccpr">обратиться в КПЧ ООН</a>.`,
            },
        ],
    },
};
