<template>
    <div class="faq">
        <h2 class="h1 faq__headline">
            {{ headline }}
        </h2>
        <div class="faq__questions">
            <faq-item
                v-for="(question, index) in questionsToShow"
                :key="question.title"
                :index="index"
                :active="question.active"
                :title="question.title"
                :text="question.text"
                @questionclick="onQuestionClick"
            />
        </div>
        <expand-button
            v-if="isMobile"
            :collapse="showAll"
            expand-text="Показать ещё вопросы"
            collapse-text="Скрыть"
            @click="() => (showAll = !showAll)"
        >
        </expand-button>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ViewportBreakpoints } from '@/constants';
import ExpandButton from '@/components/button/ExpandButton.vue';
import FaqItem from './FaqItem.vue';

interface Question {
    title: string;
    text: string;
    active?: boolean;
}

export default Vue.extend({
    components: {
        FaqItem,
        ExpandButton,
    },

    props: {
        headline: {
            type: String,
            default: 'FAQ',
        },
        questions: {
            type: Array as PropType<Question[]>,
            default: () => [],
        },
    },

    data() {
        return {
            mql: window.matchMedia(`(max-width: ${ViewportBreakpoints.Sm}px)`),
            showAll: false,
            isMobile: false,
            questionItems: this.questions.map((q) => ({ ...q, active: false })),
        };
    },

    computed: {
        questionsToShow() {
            if (this.isMobile) {
                return this.showAll
                    ? this.questionItems
                    : this.questionItems.filter((q, i) => i < 4);
            }
            return this.questionItems;
        },
    },

    created() {
        this.isMobile = this.checkMobile();
    },
    mounted() {
        this.mql.addEventListener('change', this.onScreenChange);
    },
    beforeDestroy() {
        this.mql.removeEventListener('change', this.onScreenChange);
    },

    methods: {
        onQuestionClick({ index }) {
            const clickedActiveState = this.questionsToShow[index].active;
            this.questionItems = this.questions.map((q, i) => ({
                ...q,
                active: index === i ? !clickedActiveState : false,
            }));
        },
        onScreenChange(e) {
            if (e.matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        checkMobile() {
            return window.innerWidth <= ViewportBreakpoints.Sm;
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";

.faq__headline {
    margin-bottom: 24px;
}

.faq__questions {
    margin-bottom: 16px;
}
</style>
