<template>
    <div
        class="faq__question"
        :class="{ 'faq__question--active': active }"
    >
        <button
            class="faq__question-title"
            @click="onQuestionClick(index)"
        >
            <span class="faq__question-title-text">
                <span
                    class="faq__question-plus-icon"
                    :class="active && 'faq__question-plus-icon--active'"
                ></span>
                {{ title }}
            </span>
        </button>
        <transition
            @enter="onEnter"
            @after-enter="onAfterEnter"
            @before-leave="onBeforeLeave"
            @leave="onLeave"
        >
            <div
                v-if="active"
                ref="wrapper"
                class="faq__question-text-wrapper"
            >
                <div
                    ref="inner"
                    class="faq__question-text rich-text"
                    v-html="text"
                ></div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        index: {
            type: Number,
            default: 0,
        },
        active: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
    methods: {
        onQuestionClick(index: number) {
            this.$emit('questionclick', {
                index,
            });
        },
        onEnter(el: HTMLElement) {
            this.setWrapperHeightTo(this.getContentHeight(), el);
        },
        onAfterEnter(el: HTMLElement) {
            this.setWrapperHeightTo('auto', el);
        },
        onBeforeLeave(el: HTMLElement) {
            this.setWrapperHeightTo(this.getContentHeight(), el);
        },
        onLeave(el: HTMLElement) {
            // @todo Зачем это?
            // eslint-disable-next-line no-unused-expressions
            el.scrollHeight;
            this.setWrapperHeightTo(0, el);
        },
        getContentHeight() {
            const { inner } = this.$refs;
            return inner.getBoundingClientRect().height;
        },
        setWrapperHeightTo(
            height: number | 'auto',
            el: HTMLElement = this.$refs.wrapper,
        ) {
            // eslint-disable-next-line no-param-reassign
            el.style.height = typeof height === 'number' ? `${height}px` : height;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.faq__question {
    background-color: colors.$generaptor-brand-bg;
    border-radius: 8px;
    transition: background-color 0.15s ease;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        background-color: colors.$generaptor-brand-bg-alpha;
    }

    &--active {
        &:hover {
            background-color: colors.$generaptor-brand-bg;
        }
    }

    @media (hover: none) {
        &:hover {
            background-color: colors.$generaptor-brand-bg;
        }
    }
}

.faq__question-title {
    --faq-question-color: #{colors.$secondary-grey};

    display: flex;
    align-items: center;
    padding: grid.$space-3 grid.$space-6;
    width: 100%;
    min-height: 32px;
    background: transparent;
    border: 0;
    color: colors.$secondary-grey;
    font-size: 24px;
    line-height: 1.35;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;
    appearance: none;

    @include breakpoint(md) {
        padding: grid.$space-2;
        font-size: 18px;
        line-height: 26px;
    }
}

.faq__question-title-text {
    position: relative;
    padding-left: grid.$space-6;

    @include breakpoint(md) {
        padding-left: grid.$space-4;
    }
}

.faq__question-plus-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 4px;
    left: 0;

    @include breakpoint(md) {
        width: 16px;
        height: 16px;
        top: 5px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: var(--faq-question-color);
        top: calc(50% - 3px / 2);
        left: 0;
        transition: transform 150ms ease-out;

        @include breakpoint(md) {
            height: 2px;
            top: calc(50% - 1px);
        }
    }

    &::after {
        transform: rotate(90deg);
    }
}

.faq__question-plus-icon--active {
    &::after {
        transform: rotate(0deg);
    }
}

.faq__question-text-wrapper {
    padding: 0 45px;
    border-radius: 8px;
    color: colors.$secondary-grey;
    transition: height 150ms ease-out;
    height: 0;
    overflow: hidden;

    @include breakpoint(md) {
        padding: 0 16px;
    }
}

.faq__question-text {
    padding: 0 0 24px 48px;

    @include breakpoint(md) {
        padding: 0 0 16px 32px;
        font-size: 16px;
        line-height: 1.5;
    }
}
</style>
