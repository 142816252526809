<template>
    <button
        type="button"
        class="button-expandable"
        :class="{
            'button-expandable--inverted': inverted,
            'button-expandable--outlined': outlined,
            'button-expandable--cover': cover,
        }"
        :disabled="disabled"
        v-on="$listeners"
    >
        <span v-if="!collapse">
            {{ expandText }}
        </span>
        <span v-else>
            {{ collapseText }}
        </span>
        <span
            class="button-expandable__chevron"
            :class="{
                'button-expandable__chevron--revert': collapse,
            }"
        >
            <base-icon>
                <icon-chevron-down />
            </base-icon>
        </span>
    </button>
</template>

<script lang="ts">
import Vue from 'vue';
import IconChevronDown from '@/icons/IconChevronDown.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default Vue.extend({
    components: { BaseIcon, IconChevronDown },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        collapse: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        cover: {
            type: Boolean,
            default: false,
        },
        expandText: {
            type: String,
            default: 'Подробнее',
        },
        collapseText: {
            type: String,
            default: 'Свернуть',
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.button-expandable {
    --color: #{colors.$accent};

    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    max-width: 100%;
    background: transparent;
    border: 0;
    color: var(--color);
    font-family: typography.$font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    flex-shrink: 0;
    appearance: none;

    @include breakpoint(sm) {
        font-size: 16px;
        line-height: 24px;
    }

    &:active {
        --color: #{colors.$accent-dark};
    }

    &--outlined {
        align-items: center;
        line-height: 46px;
        border: 1px solid var(--color);
        border-radius: 4px;
        padding: 0 19px;
    }
}

.button-expandable--cover {
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.button-expandable--inverted {
    --color: #{colors.$white};

    &:hover {
        --color: #{colors.$white};
    }

    &:active {
        --color: #{colors.$white};
    }
}

.button-expandable:disabled {
    --color: #{colors.$secondary-grey-light};

    pointer-events: none;
}

.button-expandable__chevron {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: 8px;

    &--revert {
        transform: rotate(180deg);
    }
}
</style>
