<template>
    <mail-to email="legal@ovdinfo.org" />
</template>

<script>
import Vue from 'vue';
import MailTo from './MailTo.vue';

export default Vue.extend({
    components: {
        MailTo,
    },
});
</script>
