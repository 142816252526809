<template>
    <button
        v-if="!link"
        class="button-tile"
        :class="{
            'button-tile--dark': dark,
        }"
        :disabled="disabled"
        v-on="$listeners"
    >
        <span class="button-tile__icon">
            <slot name="icon"></slot>
        </span>
        <span>
            <slot></slot>
        </span>
    </button>
    <a
        v-else
        class="button-tile"
        :class="{
            'button-tile--dark': dark,
            'button-tile--disabled': disabled,
        }"
        :href="to"
    >
        <span class="button-tile__icon">
            <slot name="icon"></slot>
        </span>
        <span class="button-tile__text">
            <slot></slot>
        </span>
    </a>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String,
            default: '/',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.button-tile {
    --tile-shadow: 0px 9px 15px rgba(132 165 120 / 15%);

    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    width: 171px;
    height: 100%;
    padding: 16px;
    background: colors.$white;
    border: 0;
    border-radius: 8px;
    box-shadow: var(--tile-shadow);
    font-family: typography.$font-family;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.01em;
    text-decoration: none;
    text-align: left;
    color: colors.$secondary-grey;
    cursor: pointer;
    transition: background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease, color 0.15s ease, fill 0.15s ease;
    flex-shrink: 0;
    appearance: none;

    @include breakpoint(sm) {
        width: 100%;
    }

    &:hover {
        color: colors.$secondary-grey;
        box-shadow: none;

        @media (hover: none) {
            box-shadow: var(--tile-shadow);
        }
    }
}

.button-tile:disabled,
.button-tile--disabled {
    pointer-events: none;
}

.button-tile__icon:not(:empty) {
    display: flex;
    margin-bottom: 22px;
    color: colors.$generaptor-brand;
}

.button-tile__text {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
}

.button-tile--dark {
    background-color: colors.$secondary-grey-dark;
    color: colors.$white;
    box-shadow: none;
    width: 159px;

    @include breakpoint(sm) {
        width: 100%;
    }

    &:hover {
        background-color: colors.$white;
        color: colors.$secondary-grey-dark;

        @media (hover: none) {
            background-color: colors.$secondary-grey-dark;
            color: colors.$white;
        }
    }

    .button-tile__icon {
        color: colors.$white;
    }

    &:hover .button-tile__icon {
        color: colors.$secondary-grey-dark;

        @media (hover: none) {
            color: colors.$white;
        }
    }
}
</style>
