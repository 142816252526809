<template>
    <section class="court-filing-page section section--padding-top-1 section--padding-bottom-2">
        <div class="container">
            <h1
                v-if="formStep === 'AWAITING_SCHEMA' || formStep === 'FILLING_FORM'"
            >
                <slot name="heading" />
            </h1>

            <div class="container__grid">
                <div class="formular">
                    <div class="court-filing-page__body">
                        <transition name="loading">
                            <form-skeleton
                                v-if="formStep === 'AWAITING_SCHEMA'"
                                class="court-filing-page__skeleton"
                            />

                            <div
                                v-else-if="formStep === 'FILLING_FORM'"
                                class="court-filing-page__form"
                            >
                                <form-builder :form-type="formType">
                                    <template #submit>
                                        <formular-section
                                            borderless
                                            clean
                                        >
                                            <button-tip-wrapper>
                                                <template #button>
                                                    <base-button
                                                        type="submit"
                                                        :pending="mappedStore.isFormPending"
                                                    >
                                                        Отправить
                                                    </base-button>
                                                </template>

                                                <template #hint>
                                                    <slot name="submit-hint" />
                                                </template>
                                            </button-tip-wrapper>
                                        </formular-section>
                                    </template>
                                </form-builder>
                            </div>

                            <form-result-success
                                v-else-if="formStep === 'FORM_SENT'"
                                :email="email"
                            >
                                <template #addendum>
                                    <slot name="result-addendum" />
                                </template>
                            </form-result-success>

                            <form-result-failure
                                v-else-if="formStep === 'FORM_FAILED'"
                                :start-over="startOver"
                                :email="email"
                            >
                                <template #addendum>
                                    <slot name="result-addendum" />
                                </template>
                            </form-result-failure>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { makeMappedStoreMixin } from '@/store/court-filing/utils/vuex-helpers';
import ButtonTipWrapper from '@/components/button/ButtonTipWrapper.vue';
import FormularSection from '@/components/FormularSection.vue';
import FormBuilder from '@/components/forms/court-filing/FormBuilder.vue';
import FormSkeleton from '@/components/forms/FormSkeleton.vue';
import FormResultSuccess from '@/components/forms/submission-result/SubmissionSuccess.vue';
import FormResultFailure from '@/components/forms/submission-result/SubmissionFailure.vue';

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth');

export default Vue.extend({
    components: {
        FormSkeleton,
        FormBuilder,
        FormResultSuccess,
        FormResultFailure,
        FormularSection,
        ButtonTipWrapper,
    },

    mixins: [
        makeMappedStoreMixin(({ mapGetters, mapActions }) => ({
            computed: {
                ...mapGetters([
                    'isSchemaPending', 'isFormPending', 'isFormSubmitted', 'isFormFailed',
                ]),
            },
            methods: {
                ...mapActions(['loadSchema', 'resetForm', 'prefillFormForQA']),
            },
        })),
    ],

    props: {
        /* обязательно для `makeMappedStoreMixin` */
        formType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isRetry: false,
        };
    },

    computed: {
        ...mapAuthGetters({
            isUserLoggedIn: 'isLoggedIn',
            userData: 'user',
        }),

        formStep() {
            if (this.mappedStore.isSchemaPending) {
                return 'AWAITING_SCHEMA';
            }
            if (this.isRetry) {
                return 'FILLING_FORM';
            }
            if (this.mappedStore.isFormSubmitted) {
                return 'FORM_SENT';
            }
            if (this.mappedStore.isFormFailed) {
                return 'FORM_FAILED';
            }
            return 'FILLING_FORM';
        },

        email() {
            return this.isUserLoggedIn ? this.userData.email : null;
        },
    },

    watch: {
        'mappedStore.isFormPending': function isFormPending(isPending) {
            if (isPending) {
                this.isRetry = false;
            }
        },
    },

    created() {
        /* `loadSchema` — асинхронный экшн, но не всем нужно его дожидаться */
        this.mappedStore.loadSchema().then(() => {
            this.mappedStore.prefillFormForQA();
        });
        this.mappedStore.resetForm();
    },

    methods: {
        startOver() {
            this.isRetry = true;
        },
    },
});
</script>

<style lang="scss" scoped>
    $fade-in-out-duration: 300ms;

    .court-filing-page__body {
        position: relative;
    }

    .court-filing-page__skeleton {
        opacity: 1;
        transition: opacity $fade-in-out-duration;

        &.loading-leave-active {
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            opacity: 0;
        }
    }

    .court-filing-page__form {
        &.loading-enter-active {
            opacity: 0;
        }

        &.loading-enter-to {
            position: relative;
            z-index: -1;
            transition: opacity $fade-in-out-duration;
            opacity: 1;
        }
    }
</style>
