<template>
    <!-- Docs -->
    <section
        id="docs"
        class="section section--padding-bottom-3"
    >
        <div class="container">
            <div class="docs">
                <modal-overlay
                    :is-open="isModalOpen"
                    @close="() => (isModalOpen = false)"
                >
                    <petitions-list></petitions-list>
                </modal-overlay>
                <h2 class="h1">
                    Документы для суда
                </h2>
                <p class="docs__teaser">
                    К судам надо готовить документы. Ниже мы&nbsp;описываем какие,
                    как их&nbsp;сделать и&nbsp;как подать.<br />
                    Наши документы составлены специально с&nbsp;учётом перспектив в&nbsp;ЕСПЧ.
                </p>
                <div class="docs__link">
                    <link-button
                        type="button"
                        @click="isModalOpen = true"
                    >
                        Мне нужно конкретное ходатайство
                    </link-button>
                </div>

                <first-instance-court-docs />

                <appeal-court-docs />

                <docs-group
                    title="Европейский суд по правам человека (ЕСПЧ)"
                    deadline="
                        4 месяца после рассмотрения вашей апелляции.
                        Просим обращаться к&nbsp;нам и&nbsp;пользоваться сервисом
                        не&nbsp;позднее 3 месяцев&nbsp;— чтобы у&nbsp;юристов было достаточно
                        времени на&nbsp;оказание помощи. Подать жалобу можно по нарушениям,
                        которые произошли до 16 сентября 2022 года.
                    "
                    dino-message="Главный бой еще впереди!"
                >
                    <template #description>
                        Инструкция, документы и сопровождение от юристов ОВД-Инфо
                        для обжалования решения российских судов
                        в Европейском суде по правам человека.
                    </template>

                    <p class="docs__steps-intro">
                        Для ЕСПЧ потребуются материалы дела из суда и анкета
                        жалобы. Наши юристы помогут вам с процессом
                        обращения в ЕСПЧ.
                    </p>
                    <ol class="docs__steps">
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Ознакомьтесь с материалами дела в суде
                            </p>
                            <ul class="docs__steps-step-bullets">
                                <li class="docs__steps-step-bullets-item">
                                    После 3–4 недель с момента суда второй
                                    инстанции позвоните в суд первой
                                    инстанции и договоритесь с секретарем о
                                    дате и времени ознакомления с
                                    материалами дела.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    В назначенную дату и время приедьте в
                                    канцелярию суда с паспортом и попросите
                                    ознакомиться с материалами вашего дела.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    Отфотографируйте каждую страницу дела.
                                </li>
                                <li class="docs__steps-step-bullets-item">
                                    После этого напишите нам на&nbsp;почту
                                    <mail-to email="espch@ovdinfo.org" />,
                                    указав, что вам требуется помощь
                                    с&nbsp;подачей жалобы (сами фотографии
                                    прилагать не&nbsp;надо!)
                                </li>
                            </ul>
                            <link-button
                                type="link"
                                arrow
                                :to="`${oiLegalBaseURL}/instruction/help-yourself#12-3`"
                            >
                                Инструкция по ознакомлению
                            </link-button>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Подача жалобы в ЕСПЧ
                            </p>
                            <base-button
                                view="secondary"
                                type="link"
                                :to="echrApplicationLink"
                            >
                                <template #icon>
                                    <base-icon><icon-edit /></base-icon>
                                </template>
                                Заполнить жалобу
                            </base-button>
                        </li>
                        <li class="docs__steps-step">
                            <p class="docs__steps-step-headline">
                                Отправьте жалобу
                            </p>
                            <p class="docs__steps-step-paragraph">
                                Мы подготовим вам жалобу и пригласим на подпись в наш офис.
                                Отправим сами.
                            </p>
                        </li>
                    </ol>
                </docs-group>
            </div>
        </div>
    </section>
</template>

<script>
import config from '@/config';
import DocsGroup from '@/components/page-home/docs-group/DocsGroup.vue';
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import PetitionsList from '@/components/petitions-list/PetitionsList.vue';
import MailTo from '@/components/mail-to/MailTo.vue';
import LinkButton from '@/components/button/LinkButton.vue';
import BaseButton from '@/components/button/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconEdit from '@/icons/IconEdit.vue';
import FirstInstanceCourtDocs from './FirstInstanceCourtDocs.vue';
import AppealCourtDocs from './AppealCourtDocs.vue';

export default {
    name: 'HomeDocsECHR',
    components: {
        DocsGroup,
        ModalOverlay,
        PetitionsList,
        MailTo,
        LinkButton,
        BaseButton,
        BaseIcon,
        IconEdit,
        FirstInstanceCourtDocs,
        AppealCourtDocs,
    },
    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
            echrApplicationLink: `https://espch.${config.oiLegalDomain}`,
            isModalOpen: false,
        };
    },
};
</script>
