<template>
    <a :href="`mailto:${email}`">{{ email }}</a>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        email: {
            type: String,
            required: true,
        },
    },
});
</script>
